import { ImWoman } from "react-icons/im";
import Footer from "../../components/layouts/footer/Footer";
import Header from "../../components/layouts/header/Header";
import image1 from "../../assets/images/black-pregnant-women-posing.jpg";
import image2 from "../../assets/images/postnatalproduct4.12.webp";
import image3 from "../../assets/images/portrait-mother-with-newborn-baby.jpg";
import "./services.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Services() {
  const navigate = useNavigate();
  return (
    <div className="">
      <Header />
      <div className="services">
        <section className="services__section__one">
          <h1>Providing quality care for every patient</h1>
        </section>
        <section className="services__section__two">
          <div className="services__section__two__start">
            <button className="tonal__buttons" style={{ width: "max-content" }}>
              <ImWoman />
              Maternal Health
            </button>
            <h2>Antenatal Care</h2>
            <p>
              Our antenatal care service is designed to ensure expectant mothers
              receive the highest level of care throughout their pregnancy. From
              the moment of conception through to delivery, we offer a
              comprehensive range of services that cater to every aspect of
              maternal health.
            </p>
            <h3
              onClick={() =>
                navigate("/service", { state: { type: "antenatal" } })
              }
            >
              Learn more <FaArrowRight />
            </h3>
          </div>
          <div className="services__section__two__end">
            <img alt="services" src={image1} />
          </div>
        </section>
        <section className="services__section__three">
          <div className="services__section__two__start">
            <h2>Postnatal Care</h2>
            <p>
              Our postnatal care service focuses on the well-being of both
              mother and baby after childbirth. We provide a range of services
              that support recovery, promote health, and ensure the best start
              for your newborn.
            </p>
            <h3
              onClick={() =>
                navigate("/service", { state: { type: "postnatal" } })
              }
            >
              Learn more <FaArrowRight />
            </h3>
          </div>
          <div className="services__section__two__end">
            <img alt="services" src={image2} />
          </div>
        </section>
        <section className="services__section__four">
          <div className="services__section__two__start">
            <h2>Postpartum Care</h2>
            <p>
              Our postpartum care service is dedicated to supporting mothers
              during the critical period following childbirth. We offer
              comprehensive care that addresses both physical recovery and
              emotional well-being.
            </p>
            <h3
              onClick={() =>
                navigate("/service", { state: { type: "postpartum" } })
              }
            >
              Learn more <FaArrowRight />
            </h3>
          </div>
          <div className="services__section__two__end">
            <img alt="services" src={image3} />
          </div>
        </section>
        <section className="services__section__five">
          <button className="tonal__buttons" style={{ width: "max-content" }}>
            <ImWoman />
            General health
          </button>
          <h3>
            Serum Health also provides special care service for a wide range of
            our patients
          </h3>
          <div className="services__section__five__wrap">
            <div>
              <h2
                onClick={() =>
                  navigate("/service", { state: { type: "nursing" } })
                }
              >
                Nursing home care <FaArrowRight />
              </h2>
              <p>
                Our nursing home care service provides professional medical care
                and support for the elderly and those with chronic illnesses. We
                offer personalized care plans to meet the unique needs of each
                patient, ensuring they receive the best possible care in the
                comfort of their home.
              </p>
            </div>
            <div>
              <h2
                onClick={() =>
                  navigate("/service", { state: { type: "chronic" } })
                }
              >
                Chronic Disease Management <FaArrowRight />
              </h2>
              <p>
                Our chronic disease management service is designed to provide
                consistent and effective care for patients with chronic
                conditions. We work closely with patients and their families to
                manage their health and improve their quality of life.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
