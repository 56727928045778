export const SERVICE_DATA = [
  {
    id: 1,
    type: "antenatal",
    title: "Antenatal Care",
    overview:
      "Our antenatal care service is designed to ensure expectant mothers receive the highest level of care throughout their pregnancy. From the moment of conception through to delivery, we offer a comprehensive range of services that cater to every aspect of maternal health.",
    services: [
      {
        id: 1,
        title: "Routine Checkups",
        description:
          "Regular visits to monitor the health and development of both mother and baby. These checkups include blood pressure monitoring, weight checks, and fetal heart rate monitoring.",
      },
      {
        id: 2,
        title: "Antenatal Classes",
        description:
          "Educational sessions that prepare expectant mothers for childbirth and parenthood. Topics covered include labor and delivery, breastfeeding, newborn care, and postpartum recovery.",
      },
      {
        id: 3,
        title: "Gynecologist Visits",
        description:
          "Scheduled visits with experienced gynecologists for professional medical advice, ultrasounds, and any necessary medical interventions.",
      },
      {
        id: 4,
        title: "Ultrasounds",
        description:
          " Regular imaging to monitor the baby's growth and development, detect any anomalies, and assess the health of the pregnancy.",
      },
      {
        id: 5,
        title: "Delivery Services",
        description:
          "Choose between delivering your baby at home with professional support or at our fully equipped delivery centers.",
      },
      {
        id: 6,
        title: "Nutritional Guidance",
        description:
          "Personalized dietary plans to ensure the mother receives the necessary nutrients for a healthy pregnancy.",
      },
      {
        id: 7,
        title: "Exercise and Physiotherapy",
        description:
          "Tailored exercise programs and physiotherapy sessions to maintain physical health and manage pregnancy-related discomforts.",
      },
    ],
    benefits: [
      "Early detection and management of potential complications",
      "Comprehensive education and preparation for childbirth",
      "Continuous monitoring and support from a team of healthcare professionals",
      "Personalized care plans to meet individual needs",
    ],
  },
  {
    id: 2,
    type: "postnatal",
    title: "Postnatal Care",
    overview:
      "Our postnatal care service focuses on the well-being of both mother and baby after childbirth. We provide a range of services that support recovery, promote health, and ensure the best start for your newborn.",
    services: [
      {
        id: 1,
        title: "	Medical Checkups",
        description:
          "Regular health assessments for both mother and baby to monitor recovery and development.",
      },
      {
        id: 2,
        title: "	Breastfeeding Support",
        description:
          "Guidance and support from lactation consultants to help with breastfeeding techniques and address any challenges.",
      },
      {
        id: 3,
        title: "	Postpartum Recovery Care",
        description:
          "Services such as physiotherapy and pain management to assist mothers in recovering from childbirth.",
      },
      {
        id: 4,
        title: "	Emotional Support",
        description:
          " Counseling and support groups to help new mothers cope with the emotional changes and challenges of motherhood",
      },
      {
        id: 5,
        title: "Emotional Support",
        description:
          "Convenient at-home immunization services according to the recommended schedule.",
      },
    ],
    benefits: [
      "Professional support for physical and emotional recovery",
      "	Expert advice on newborn care and feeding",
      "	Expert advice on newborn care and feeding",
      "	Continuous monitoring and health assessments to ensure well-being",
    ],
  },
  {
    id: 3,
    type: "postpartum",
    title: "Postpartum Care",
    overview:
      "Our postpartum care service is dedicated to supporting mothers during the critical period following childbirth. We offer comprehensive care that addresses both physical recovery and emotional well-being.",
    services: [
      {
        id: 1,
        title: "Postpartum Health Assessment",
        description:
          "Regular checkups to monitor the mother's recovery and address any postpartum complications.",
      },
      {
        id: 2,
        title: "	Emotional and Mental Support",
        description:
          "Counseling sessions and support groups to help mothers deal with postpartum depression, anxiety, and other emotional challenges.",
      },
      {
        id: 3,
        title: "	Physical Recovery Support",
        description:
          " Physiotherapy, pain management, and exercises to aid in physical recovery and strengthen the body after childbirth",
      },
      {
        id: 4,
        title: "		Breastfeeding and Newborn Care Guidance",
        description:
          " Expert advice and support for breastfeeding, baby care, and establishing healthy routines.",
      },
    ],
    benefits: [
      "Comprehensive support for both physical and emotional recovery",
      "		Access to professional healthcare and advice",
      "		Improved postpartum recovery outcomes",
      "		Enhanced bonding and care for the newborn",
    ],
  },
  {
    id: 4,
    type: "nursing",
    title: "Nursing Home Care Service",
    overview:
      "At Serum Health, we provide professional nursing home care services tailored to the unique needs of the elderly and those with chronic illnesses. Our goal is to ensure that our clients receive the highest quality of care in the comfort and familiarity of their homes, promoting their well-being and independence.",
    services: [
      {
        id: 1,
        title: "Skilled Nursing Care",
        description:
          "Professional nursing services for medical needs, including wound care, medication administration, and monitoring of health conditions.",
      },
      {
        id: 2,
        title: "		Personal Care Assistance",
        description:
          "Comprehensive care plans for managing chronic illnesses such as diabetes, hypertension, and heart disease.",
      },
      {
        id: 3,
        title: "	Daily Living Support",
        description:
          "Help with daily activities such as bathing, dressing, mobility, and meal preparation.",
      },
      {
        id: 4,
        title: "		Chronic Disease Management",
        description:
          "Counseling and companionship to support mental and emotional well-being.",
      },
      {
        id: 5,
        title: "	Specialized Care",
        description:
          " Regular checkups and monitoring to manage health conditions and prevent complications.",
      },
    ],
    benefits: [
      "Comprehensive support for both physical and emotional recovery",
      "		Each client receives individualized care tailored to their specific health conditions and personal preferences.",
      "		Our services are designed to improve the overall quality of life by providing professional care in a comfortable and familiar setting",
      "		Families can rest assured that their loved ones are receiving the best possible care from experienced and compassionate professionals",
    ],
  },
  {
    id: 5,
    type: "chronic",
    title: "Chronic Disease Management",
    overview:
      "Our chronic disease management service is designed to provide consistent and effective care for patients with chronic conditions. We work closely with patients and their families to manage their health and improve their quality of life.",
    services: [
      {
        id: 1,
        title: "•	Personalized Care Plans",
        description:
          "Ongoing assessment and monitoring to track the progress and manage the condition effectively.",
      },
      {
        id: 2,
        title: "	•	Medication Management ",
        description:
          "Assistance with medication schedules, refills, and adherence to prescribed treatments.",
      },
      {
        id: 3,
        title: "	•	Dietary and Lifestyle Guidance ",
        description:
          " Nutrition and lifestyle recommendations to help manage the condition and improve overall health.",
      },
      {
        id: 4,
        title: "		•	Support and Education",
        description:
          "Resources and education for patients and families to understand and manage the chronic condition.",
      },
    ],
    benefits: [
      "Improved management of chronic conditions",
      "Reduced risk of complications",
      "Enhanced quality of life for patients",
      "Support and education for patients and their families",
      "Professional medical oversight and guidance",
    ],
  },
];
