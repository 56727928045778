import "./Immunization.css";
import Header from "../../components/layouts/header/Header";
import Footer from "../../components/layouts/footer/Footer";
import { MdArrowOutward, MdCallMade } from "react-icons/md";
import image1 from "../../assets/images/baby.png";
import video1 from "../../assets/videos/mixkit-little-girl-coughing-loudly-4524-hd-ready.mp4";
import { BsLadder, BsQuestionCircle } from "react-icons/bs";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs, { init } from "@emailjs/browser";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "../../components/ui/modal/Modal";
import logo from "../../assets/icons/SERUM NEW LOGO.png";

function Immunization() {
  const navigate = useNavigate();
  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
  });

  const [isOpen, setIsOPen] = useState(false);
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    service: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModalClick = () => {
    setIsOPen(!isOpen);
  };

  const closeModal = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      service: "",
    });
    setIsOPen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, submitting: true });

    try {
      setFormStatus({ ...formStatus, submitting: true });

      //Email JS send form logic
      const data = formData;

      emailjs
        .send("service_gv8dt8j", "template_84syenr", data, "fL4UraYJiFZYYrz6z")
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        location: "",
        service: "",
      });
      setFormStatus({ submitting: false, submitted: true });
      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 15000);
    } catch (error) {
      console.error("Error sending form:", error);
      setFormStatus({ submitting: false, submitted: true });
    }
  };

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  return (
    <div>
      <Header />
      <div className="im">
        <section className="im__section__one">
          <div className="im__section__one__start">
            <h1>No child deserves to be sick</h1>
            <p>
              At Serum Health, we understand the importance of timely
              vaccinations in safeguarding your child's health. Our immunization
              service is designed to provide comprehensive and convenient
              vaccination schedules in the comfort of your home.
            </p>
            <span>
              <button onClick={handleModalClick}>Book appointment</button>
            </span>
          </div>
          <div className="im__section__one__end">
            <video autoPlay loop muted>
              <source src={video1} type="video/mp4" />
            </video>
          </div>
        </section>
        <section className="im__section__tomatoes">
          <button className="tonal__buttons">
            <BsLadder /> Stages
          </button>
          <h1>Our Immunization Schedule</h1>
          <p>
            We adhere to a comprehensive immunization schedule to ensure that
            your baby receives all essential vaccines at the appropriate times.
            Here is the detailed schedule we follow:
          </p>
          <div className="im__section__stages">
            <div>
              <h1>At Birth</h1>
              <ul>
                <li>- BCG (Bacillus Calmette-Guérin) vaccine</li>
                <li>- Hepatitis B vaccine</li>
                <li>- Oral Polio Vaccine (OPV)</li>
              </ul>
            </div>
            <div>
              <h1>6 Weeks</h1>
              <ul>
                <li>- Pentavalent vaccine (DPT-HepB-Hib) </li>
                <li>- OPV</li>
                <li>- Pneumococcal Conjugate Vaccine (PCV)</li>
                <li>- Rotavirus Vaccine (OPV)</li>
              </ul>
            </div>
            <div>
              <h1>10 Weeks</h1>
              <ul>
                <li>- Pentavalent vaccine (DPT-HepB-Hib) </li>
                <li>- OPV</li>
                <li>- PCV</li>
              </ul>
            </div>
            <div>
              <h1>14 Weeks</h1>
              <ul>
                <li>- Pentavalent vaccine (DPT-HepB-Hib) </li>
                <li>- OPV</li>
                <li>- PCV</li>
                <li>- Rotavirus Vaccine (OPV)</li>
              </ul>
            </div>
            <div>
              <h1>6 Months</h1>
              <ul>
                <li>- Measles vaccine</li>
              </ul>
            </div>
            <div>
              <h1>9 Months</h1>
              <ul>
                <li>- Yellow Fever vaccine</li>
                <li>- Measles vaccine</li>
              </ul>
            </div>
            <div>
              <h1>12 Months</h1>
              <ul>- Meningitis A vaccine</ul>
            </div>
          </div>
        </section>
        <section className="home__section__eba">
          <button className="tonal__buttons">
            <BsQuestionCircle /> FAQ
          </button>
          <h1>Questions parents usually ask</h1>
          <p>
            If you have any questions or need further information about our
            immunization service, please do not hesitate to contact us. Our team
            is here to assist you and provide the best care for your child.
          </p>
          <div className="home__section__eba__faq__wrap">
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select1")}
              >
                <h1>01</h1>
                <span>
                  Why is it important to follow the immunization schedule?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select1 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select1 ? " open" : ""}`}>
                <p>
                  Following the immunization schedule ensures that your child is
                  protected against preventable diseases at the appropriate
                  ages. Timely vaccinations are crucial for effective immunity.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select2")}
              >
                <h1>02</h1>
                <span>
                  Are the vaccines safe?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select2 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select2 ? " open" : ""}`}>
                <p>
                  Yes, the vaccines we administer are safe and have been
                  thoroughly tested and approved by health authorities. Our
                  healthcare professionals are trained to handle vaccines and
                  administer them safely.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select3")}
              >
                <h1>03</h1>
                <span>
                  What should I do if my child misses a scheduled vaccine?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select3 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select3 ? " open" : ""}`}>
                <p>
                  If your child misses a scheduled vaccine, please contact us
                  immediately. We will help you reschedule and ensure your child
                  stays on track with the immunization schedule.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select4")}
              >
                <h1>04</h1>
                <span>
                  Can my child receive multiple vaccines in one visit?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select4 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select4 ? " open" : ""}`}>
                <p>
                  Yes, it is safe for children to receive multiple vaccines in
                  one visit. This approach is often recommended to ensure timely
                  protection against various diseases.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div class="custom-shape-divider-top-1722811141">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <section className="im__section__beans">
          <div className="im__section__beans__start">
            <button className="tonal__buttons" onClick={() => navigate("/")}>
              <MdCallMade /> Get started
            </button>
            <h1>
              Life-changing care for you and transformative value for your
              well-being.
            </h1>
          </div>

          <div className="im__section__beans__end">
            <img src={image1} alt="child" />
          </div>
        </section>
      </div>
      <Footer />

      {/* FORM MODAL */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="modal__form">
          <img alt="logo" src={logo} />
          <h1>Complete this form to book an appointment</h1>
          <form onSubmit={handleOnSubmit} className="modal__form__container">
            <label htmlFor="firstName">First Name</label>
            <input
              onChange={handleOnChange}
              name="firstName"
              type="text"
              id="firstName"
              required
              value={formData.firstName}
            />

            <label htmlFor="lastName">Last Name:</label>
            <input
              onChange={handleOnChange}
              name="lastName"
              type="text"
              id="lastName"
              required
              value={formData.lastName}
            />

            <label htmlFor="email">Email</label>
            <input
              onChange={handleOnChange}
              name="email"
              type="email"
              id="email"
              required
              value={formData.email}
            />

            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              onChange={handleOnChange}
              name="phoneNumber"
              type="number"
              id="phoneNumber"
              required
              value={formData.phoneNumber}
            />

            <label htmlFor="location">Location</label>
            <select
              onChange={handleOnChange}
              name="location"
              required
              value={formData.location}
            >
              <option value="">--</option>
              <option value="Abuja">Abuja</option>
              <option value="Lagos">Lagos</option>
            </select>

            <label htmlFor="service">Service</label>
            <select
              onChange={handleOnChange}
              name="service"
              required
              value={formData.service}
            >
              <option value="">--</option>
              <option value="Antenatal care">Antenatal care</option>
              <option value="Postpartum care">Postpartum care</option>
              <option value="Home care">Home care</option>
              <option value="Immunization">Immunization</option>
              <option value="Chronic disease care">Chronic disease care</option>
              <option value="Supplements">Supplements</option>
            </select>
            {formStatus.submitted && (
              <div className="modal__form__message">
                Application successful! You willl receive a follow up email or
                call.
              </div>
            )}

            <span>
              <button type="button" onClick={closeModal}>
                Cancel form
              </button>
              <button type="submit" disabled={formStatus.submitting}>
                {formStatus.submitting ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  "Submit form"
                )}
              </button>
            </span>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default Immunization;
