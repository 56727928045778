import "./community.css";
import Header from "../../components/layouts/header/Header";
import Footer from "../../components/layouts/footer/Footer";
import image1 from "../../assets/images/three-african-american-girls-sitting-table-caffe-looking-mobile-phone.jpg";
import { GiKnot } from "react-icons/gi";
import { FaWhatsapp } from "react-icons/fa";

function Community() {
  return (
    <>
      <Header />
      <div className="cm">
        <section className="cm__section__one">
          <div className="cm__section__one__start">
            <h1>United Women, Stronger Together</h1>
            <p>
              At Serum Health, we believe in the power of community to support
              and uplift each other through the beautiful journey of motherhood.
              Whether you're an expectant mum preparing for the arrival of your
              little one or a new mum navigating the early days of parenthood,
              we have a community for you.
            </p>
            <span>
              <button>
                <FaWhatsapp />{" "}
                <a
                  href="https://wa.me/2348167237000"
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Talk to an admin
                </a>
              </button>
            </span>
          </div>
          <div className="cm__section__one__end">
            <img alt="hero--img" src={image1} />
          </div>
        </section>
        <section className="cm__section__two">
          <button
            className="tonal__buttons"
            style={{ backgroundColor: "white" }}
          >
            <GiKnot /> Together
          </button>
          <h1>Two communities, One family</h1>

          <div className="cm__section__two__wrap">
            <div className="cm__section__two__start ">
              <h1>Expectant Mums Community</h1>
              <p>
                Our community for expectant mums is a safe space where you can
                connect with other pregnant women, share your experiences, and
                find support as you prepare for childbirth. From helpful tips on
                prenatal care to advice on managing pregnancy symptoms, our
                community is here to provide the guidance and encouragement you
                need. Join us to connect with others who understand what you're
                going through and to access valuable resources and expert
                advice.
              </p>
              <h1>New Mums Community</h1>
              <p>
                The early days of motherhood can be both joyful and challenging.
                Our new mums community is designed to provide a supportive
                environment where you can share your experiences, ask questions,
                and find comfort in knowing you're not alone. Whether you're
                dealing with sleepless nights, breastfeeding challenges, or just
                need a place to share your baby’s milestones, this community
                offers a wealth of support and practical tips from fellow mums
                and healthcare professionals.
              </p>
            </div>
            <div className="cm__section__two__end">
              <div class="box">
                <span class="box__top-border"></span>

                <span class="box__right-border box__right-border--top"></span>
                <span class="box__right-border box__right-border--bottom"></span>
                <button
                  className="tonal__buttons"
                  style={{
                    backgroundColor: "#25D366",
                    color: "white",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  <FaWhatsapp />{" "}
                  <a
                    href=" https://chat.whatsapp.com/LihXVHKH6r3BpWb6eEfywz"
                    target="_blank"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Join Expectant Mum Group
                  </a>
                </button>
                <button
                  className="tonal__buttons"
                  style={{
                    backgroundColor: "#DCF8C6",
                    color: "black",
                    marginLeft: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  <FaWhatsapp />{" "}
                  <a
                    href="https://chat.whatsapp.com/KVd4kj4j0XWHwBPBwrDMuD"
                    target="_blank"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Join New Mums Group
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Community;
