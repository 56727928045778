import Header from "../../components/layouts/header/Header";
import Footer from "../../components/layouts/footer/Footer";
import "./home.css";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useRef, useEffect } from "react";
import { BsPeople, BsQuestionCircle } from "react-icons/bs";
import { PiPhone } from "react-icons/pi";
import heroImg from "../../assets/images/5_x_more_report.png__760x530_subsampling-2.png";
import image1 from "../../assets/images/vonecia-carswell-0aMMMUjiiEQ-unsplash.jpg";
import image2 from "../../assets/images/topsphere-media-EkHXa6b8vCY-unsplash.jpg";
import image3 from "../../assets/icons/SurgeryPlus COE Icon.svg";
import image3b from "../../assets/icons/womens-health.png";
import image4 from "../../assets/icons/Virtual MSK Care Icon.svg";
import image5 from "../../assets/icons/Virtual Primary Care.svg";
import image6 from "../../assets/icons/Virtual Urgent Care.svg";
import image7 from "../../assets/icons/behaviour.png";
import image8 from "../../assets/icons/children.png";
import image9 from "../../assets/icons/Mental Health.svg";
import image10 from "../../assets/icons/mens-haelth.png";
import image11 from "../../assets/icons/senior-health.png";
import image12 from "../../assets/icons/manage.png";
import image14 from "../../assets/images/testimonial.png.webp";
import image15 from "../../assets/images/stat.png.webp";
import image16 from "../../assets/images/icon-with-content.png.webp";
import { GrResources } from "react-icons/gr";
import { BiInjection } from "react-icons/bi";
import { FiTarget } from "react-icons/fi";
import { ImLeaf } from "react-icons/im";
import { FaWhatsapp } from "react-icons/fa";
import Modal from "../../components/ui/modal/Modal";
import logo from "../../assets/icons/SERUM NEW LOGO.png";
import "../../components/ui/modal/modal.children/modal.form.css";
import emailjs, { init } from "@emailjs/browser";
import ClipLoader from "react-spinners/ClipLoader";

function Home() {
  const navigate = useNavigate();
  const [direction, setDirection] = useState("normal");
  const containerRef = useRef(null);
  const [isOpen, setIsOPen] = useState(false);
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    service: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModalClick = () => {
    setIsOPen(!isOpen);
  };

  const closeModal = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      service: "",
    });
    setIsOPen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, submitting: true });

    try {
      setFormStatus({ ...formStatus, submitting: true });

      //Email JS send form logic
      const data = formData;

      emailjs
        .send("service_gv8dt8j", "template_84syenr", data, "fL4UraYJiFZYYrz6z")
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        location: "",
        service: "",
      });
      setFormStatus({ submitting: false, submitted: true });
      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 15000);
    } catch (error) {
      console.error("Error sending form:", error);
      setFormStatus({ submitting: false, submitted: true });
    }
  };

  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
  });

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const onScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setDirection("normal");
      } else {
        setDirection("reverse");
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const targetDivRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(image14);

  useEffect(() => {
    const handleScroll = () => {
      if (targetDivRef.current) {
        const divHeight = targetDivRef.current.offsetHeight;
        const thirdHeight = divHeight / 6;

        // Calculate scroll points
        const scrollPoints = [
          targetDivRef.current.offsetTop + thirdHeight,
          targetDivRef.current.offsetTop + 3 * thirdHeight,
        ];

        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        // Perform actions based on scroll position
        if (scrollTop >= scrollPoints[0] && scrollTop < scrollPoints[1]) {
          setImageUrl(image15);
        } else if (scrollTop >= scrollPoints[1]) {
          setImageUrl(image16);
        } else {
          setImageUrl(image14);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="home">
        <section className="home__section__one">
          <div className="home__section__one__start">
            <h1 className="static-text">Exceptional Healthcare </h1>
            <section className="home__section__xx">
              <div className="static-text">For</div>
              <div className="wrapper">
                <ul className="dynamic-text">
                  <li>
                    <span>Moms</span>
                  </li>
                  <li>
                    <span>Newborns</span>
                  </li>
                  <li>
                    <span>The Elderly</span>
                  </li>
                  <li>
                    <span>The Sick</span>
                  </li>
                </ul>
              </div>
            </section>
            <h1>Delivered to Your Doorstep</h1>
            <p>
              Comprehensive, personalized care for mothers, newborns, the
              elderly, and the sick. Experience the comfort of expert healthcare
              services in the convenience of your home
            </p>
            <span>
              <button onClick={handleModalClick}>Book appointment</button>
              <button>
                <PiPhone />{" "}
                <a
                  href="tel:+2348167237000"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <h3> Call now</h3>
                </a>
              </button>
            </span>
          </div>
          <div className="home__section__one__end">
            <img alt="hero--img" src={heroImg} />
          </div>
        </section>
        <section className="home__section__one__amala">
          <div className="slider-container" ref={containerRef}>
            <div className={`slider-content ${direction}`}>
              <div className="slider-item">
                <img alt="icon" src={image9} />
                <p>Immunization </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image8} />
                <p>Sexual health </p>
              </div>
              {/* <div className="slider-item">
                <img alt="icon" src={image7} />
                <p>Antenatal </p>
              </div> */}
              <div className="slider-item">
                <img alt="icon" src={image12} />
                <p>Postpartum </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image10} />
                <p>Postnatal </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image11} />
                <p>Elder care </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image3b} />
                <p>Antenatal</p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image4} />
                <p>Children's health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image5} />
                <p>Chronic disease management </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image6} />
                <p>Follow up </p>
              </div>

              {/* Repeat items for infinite effect */}
              <div className="slider-item">
                <img alt="icon" src={image9} />
                <p>Immunization </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image8} />
                <p>Sexual health </p>
              </div>
              {/* <div className="slider-item">
                <img alt="icon" src={image7} />
                <p>Antenatal </p>
              </div> */}
              <div className="slider-item">
                <img alt="icon" src={image12} />
                <p>Postpartum </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image10} />
                <p>Postnatal </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image11} />
                <p>Elder care </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image3b} />
                <p>Antenatal</p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image4} />
                <p>Children's health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image5} />
                <p>Chronic disease management </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image6} />
                <p>Follow up </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image9} />
                <p>Immunization </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image8} />
                <p>Sexual health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image7} />
                <p>Mental Health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image3b} />
                <p>Womens health</p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image4} />
                <p>Children's health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image5} />
                <p>Chronic disease management </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image6} />
                <p>Follow up </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image10} />
                <p>Mens health </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image11} />
                <p>Elder care </p>
              </div>
              <div className="slider-item">
                <img alt="icon" src={image12} />
                <p>Manage your condition </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="home__section__egusi">
          <button className="tonal__buttons">
            <FiTarget /> Wide impact
          </button>
          <h1>
            Life-changing care for you and transformative value for your
            well-being.
          </h1>
          <div className="home__section__egusi__wrap">
            <div className="home__section__egusi__div ">
              <h3>88%</h3>
              <p>of members improve with care</p>
            </div>
            <div className="home__section__egusi__div ">
              <h3>₦105K</h3>
              <p>per-member-per-year reduction in health care costs</p>
            </div>
            <div className="home__section__egusi__div ">
              <h3>3X</h3>
              <p>utilization over traditional Health providers</p>
            </div>
          </div>
        </section> */}
        <section className="home__section__dodo">
          <button
            className="tonal__buttons"
            style={{ backgroundColor: "#e2e8f1" }}
          >
            <ImLeaf /> Changing health care
          </button>
          {/* <h1>Discover and Explore Serum Health Packages</h1> */}
          <div className="home__section__dodo__wrap" ref={targetDivRef}>
            <div className="home__section__dodo__stick">
              <img alt="health" src={imageUrl} />
            </div>
            <div className="home__section__dodo__text">
              <div>
                <h1>Comprehensive Care for Women’s Health</h1>
                <p>
                  At Serum, we’re dedicated to supporting every woman through
                  her journey—from antenatal to postpartum care. Our services
                  cater to families, couples, adults, teens, and children
                  (0-17), offering holistic support for physical well-being and
                  overall health. Whether navigating pregnancy, postpartum
                  recovery, or other health needs, our goal is to ensure that
                  women can fully engage with their families, careers, and daily
                  lives.
                </p>
              </div>
              <div>
                <h1>A Personalized Experience for Every Stage</h1>
                <p>Serum provides tailored support to meet your needs:</p>
                <ul>
                  <li>
                    Immediate Access to Care: Choose from self-guided programs,
                    therapy, and medication management designed to fit your
                    unique needs and preferences.
                  </li>
                  <li>
                    Flexible Options: Enjoy care in a format that suits your
                    lifestyle—whether in-person, via video conferencing, or
                    through text messaging.
                  </li>
                  <li>
                    24/7 Concierge Support: Our dedicated concierge care
                    navigator team is available around the clock to assist with
                    your health journey.
                  </li>
                  <li>
                    Compassionate Care: Benefit from providers who are trained
                    in culturally responsive care and bring diverse perspectives
                    to support your well-being.
                  </li>
                </ul>
              </div>
              <div>
                <h1>Proven Results</h1>
                <p>
                  Our evidence-based approach ensures effective care, backed by
                  peer-reviewed research. With Serum, women experience
                  significant improvements in their health and achieve lasting
                  results. You can trust that reaching out to Serum will help
                  you feel better and maintain productivity and engagement in
                  all aspects of life.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="home__section__fufu">
          <button
            className="tonal__buttons"
            style={{ backgroundColor: "#e2e8f1" }}
          >
            <GrResources /> Resources
          </button>
          <h1>Discover and Explore Serum Health Packages</h1>
          <p>
            Uncover a range of tailored serum health packages designed to meet
            your unique wellness needs. Dive into our comprehensive selection to
            find the perfect solutions for enhancing your health and vitality.
            Explore the possibilities and embark on a journey towards optimal
            well-being with our expertly crafted packages.
          </p>
          <div className="home__section__fufu__wrap">
            <div className="home__section__fufu__div ">
              <img alt="health" src={image2} />
              <h3>Personalized care</h3>
              <p>
                From maternity care to chronic disease management, we cover all
                aspects of healthcare.
              </p>
            </div>
            <div className="home__section__fufu__div">
              <img alt="health" src={image1} />
              <h3>Community</h3>
              <p>
                At Serum Health, we offer more than just immunization services;
                we provide a supportive online community for expectant and new
                mothers.
              </p>
            </div>
            <div className="home__section__fufu__div">
              <img alt="health" src={image3} />
              <h3>Immunization</h3>
              <p>
                Serum Health offers comprehensive immunization services to keep
                you and your loved ones protected from preventable diseases.
                home.
              </p>
            </div>
          </div>
        </section>
        <section className="home__section__eba" id="faq">
          <button className="tonal__buttons">
            <BsQuestionCircle /> FAQ
          </button>
          <h1>Frequntly asked question</h1>
          <p>
            Find quick answers to common queries about our services, policies,
            and procedures in our Frequently Asked Questions section. We’ve
            compiled helpful information to address your most pressing concerns
            and provide clarity on various topics. If you have additional
            questions or need further assistance, our support team is here to
            help you every step of the way.
          </p>
          <div className="home__section__eba__faq__wrap">
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select1")}
              >
                <h1>01</h1>
                <span>
                  What qualifications do your nurses have?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select1 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select1 ? " open" : ""}`}>
                <p>
                  Our nurses are highly qualified and experienced professionals
                  who have undergone rigorous training and certification. They
                  are dedicated to providing exceptional care and support.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select2")}
              >
                <h1>02</h1>
                <span>
                  How do you create a personalized care plan?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select2 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select2 ? " open" : ""}`}>
                <p>
                  We conduct a thorough assessment of the client's health
                  status, medical history, and personal preferences to create a
                  customized care plan that meets their specific needs.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select3")}
              >
                <h1>03</h1>
                <span>
                  How do you ensure the quality of care?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select3 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select3 ? " open" : ""}`}>
                <p>
                  We maintain high standards of care through continuous training
                  of our staff, regular supervision, and feedback from clients
                  and their families.
                </p>
              </div>
            </div>
            <div className="lp__faq">
              <div
                className="lp__faq__question"
                onClick={() => handleFaqClick("select4")}
              >
                <h1>04</h1>
                <span>
                  Can I make changes to the care plan if needed?
                  <MdArrowOutward
                    className={`lp__arrow${faq.select4 ? " open" : ""}`}
                  />
                </span>
              </div>
              <div className={`lp__faq__answer${faq.select4 ? " open" : ""}`}>
                <p>
                  Yes, care plans can be adjusted based on the client’s changing
                  needs and preferences. We work closely with families to ensure
                  the best possible care
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="home__section__end">
          <h1>Empower your people to feel their best</h1>
          <button>
            <FaWhatsapp />{" "}
            <a
              href="https://wa.me/2348167237000"
              target="_blank"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Chat support
            </a>
          </button>
        </section>
      </div>
      <Footer />

      {/* FORM MODAL */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="modal__form">
          <img alt="logo" src={logo} />
          <h1>Complete this form to book an appointment</h1>
          <form onSubmit={handleOnSubmit} className="modal__form__container">
            <label htmlFor="firstName">First Name</label>
            <input
              onChange={handleOnChange}
              name="firstName"
              type="text"
              id="firstName"
              required
              value={formData.firstName}
            />

            <label htmlFor="lastName">Last Name:</label>
            <input
              onChange={handleOnChange}
              name="lastName"
              type="text"
              id="lastName"
              required
              value={formData.lastName}
            />

            <label htmlFor="email">Email</label>
            <input
              onChange={handleOnChange}
              name="email"
              type="email"
              id="email"
              required
              value={formData.email}
            />

            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              onChange={handleOnChange}
              name="phoneNumber"
              type="number"
              id="phoneNumber"
              required
              value={formData.phoneNumber}
            />

            <label htmlFor="location">Location</label>
            <select
              onChange={handleOnChange}
              name="location"
              required
              value={formData.location}
            >
              <option value="">--</option>
              <option value="Abuja">Abuja</option>
              <option value="Lagos">Lagos</option>
            </select>

            <label htmlFor="service">Service</label>
            <select
              onChange={handleOnChange}
              name="service"
              required
              value={formData.service}
            >
              <option value="">--</option>
              <option value="Antenatal care">Antenatal care</option>
              <option value="Postpartum care">Postpartum care</option>
              <option value="Home care">Home care</option>
              <option value="Immunization">Immunization</option>
              <option value="Chronic disease care">Chronic disease care</option>
              <option value="Supplements">Supplements</option>
            </select>
            {formStatus.submitted && (
              <div className="modal__form__message">
                Application successful! You willl receive a follow up email or
                call.
              </div>
            )}

            <span>
              <button type="button" onClick={closeModal}>
                Cancel form
              </button>
              <button type="submit" disabled={formStatus.submitting}>
                {formStatus.submitting ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  "Submit form"
                )}
              </button>
            </span>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Home;
